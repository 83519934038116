@font-face {
  font-family: 'Object Sans';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url(https://assets.frontier.com/PPObjectSans-Regular.woff2);
  src: url(https://assets.frontier.com/PPObjectSans-Regular.eot);
  src: url(https://assets.frontier.com/PPObjectSans-Regular.otf);
  src: url(https://assets.frontier.com/PPObjectSans-Regular.ttf);
  src: url(https://assets.frontier.com/PPObjectSans-Regular.woff);
}

@font-face {
  font-family: 'Object Sans';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url(https://assets.frontier.com/PPObjectSans-Medium.woff2);
  src: url(https://assets.frontier.com/PPObjectSans-Medium.eot);
  src: url(https://assets.frontier.com/PPObjectSans-Medium.otf);
  src: url(https://assets.frontier.com/PPObjectSans-Medium.ttf);
  src: url(https://assets.frontier.com/PPObjectSans-Medium.woff);
}

@font-face {
  font-family: 'Object Sans';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url(https://assets.frontier.com/PPObjectSans-Bold.woff2);
  src: url(https://assets.frontier.com/PPObjectSans-Bold.eot);
  src: url(https://assets.frontier.com/PPObjectSans-Bold.otf);
  src: url(https://assets.frontier.com/PPObjectSans-Bold.ttf);
  src: url(https://assets.frontier.com/PPObjectSans-Bold.woff);
}

@font-face {
  font-family: 'Bandwidth Display';
  font-style: normal;
  font-display: swap;
  src: url(https://assets.frontier.com/BandwidthDisplay_Rg.woff2);
  src: url(https://assets.frontier.com/BandwidthDisplay_Rg.otf);
  src: url(https://assets.frontier.com/BandwidthDisplay_Rg.ttf);
  src: url(https://assets.frontier.com/BandwidthDisplay_Rg.woff);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  font-family: 'Object Sans', sans-serif;
}

header {
  display: flex;
  justify-content: space-between;
  height: 110px;
  width: 100vw;
  background-color: #eaeaea;
  margin: 0;
  padding: 0.5em 1em;
}

section {
  display: flex;
  flex-direction: column;
}

section.title {
  flex-wrap: wrap;
  text-align: left;
  justify-content: center;
  width: 35%;
  min-width: 315px;
}

h3 {
  font-size: 2em;
  font-family: 'Bandwidth Display', sans-serif;
  color: #141928;
  position: absolute;
  top: 0.4em;
  left: 0.7em;
  width: 35%;
  max-width: 350px;
  z-index: 1;
}

canvas {
  margin-top: 4px;
  margin-bottom: 4px;
  height: 20px;
  width: 100%;
  background-color: #ff0037;
}

section.sign-in-out {
  justify-content: space-between;
  margin: auto 0.5em;
  width: 40%;
  min-width: 232px;
}

section button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  width: 100px;
  height: 4em;
  text-align: center;
  padding: 1.5em 2em;
  font-size: 0.75em;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #ff0037;
  box-shadow: 2px 2px 6px #141414;
  font-weight: bold;
  color: #ffffff;
  border: none;
  border-radius: 6em;
  transition: all 800ms ease-out;
}

section button:hover {
  background-color: #141928;
}


.current-user {
  display: flex;
  font-size: 16px;
  color: #090101;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 1em;
}

#appcard-container {
  display: inline-block;
  padding: 2em 1em;

}

#user-displayname {
  font-weight: bold;
  margin: 0 0.5em;
}

#dashboard-container {
  display: flex;
  justify-content: stretch;  
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em;
  width: 100%;
  height: 250px;
  border-radius: 12px;
  background-color: #ededed;
}

.wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  min-height: 100vh; 
  padding: 0;
  background-color: #141928;
}

.login-text {
  text-align: justify;
  border-bottom: 2px solid #a8aab1;
  padding-bottom: 1em;
}

.login-title {
  width: 100%;
  font-size: 2em;
  font-family: 'Bandwidth Display', sans-serif;
  color: #ff0037;
  border-bottom: 2px solid #a8aab1;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: space-between;
  min-height: 100vh; 
  padding: 0;
  background-color: #141928;
}

.full-page-loader {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: white;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-page-loader .lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.full-page-loader .lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #bcbcbc;
  border-color: #bcbcbc transparent #bcbcbc transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}